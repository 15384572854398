<template>
  <v-skeleton-loader
    type="card, article"
    :loading="$store.state.session.status === 'init'"
  >
    <back-toolbar title="menu.profile"></back-toolbar>

    <v-img
      :class="profile.profileBackground ? '' : 'profile-gradient'"
      height="220px"
      cover
      style="position: absolute"
      :src="backgroundImage"
      :lazy-src="backgroundImage"
    >
    </v-img>

    <v-container if="!$store.state.loading" class="px-0 pt-0">
      <v-btn
        style="top: 20px"
        class="image-action"
        :ripple="false"
        icon
        absolute
        right
        @click="(dialogLabel = $t('profile.background')), (sheet = true)"
        color="white"
      >
        <v-icon> mdi-camera </v-icon>
      </v-btn>
      <v-container class="pa-0" style="margin-top: 80px">
        <v-avatar
          style="border: 2px solid #fff; overflow: visible; z-index: 2"
          size="116"
          class="d-block mx-auto"
        >
          <v-img
            style="background-color: grey"
            :src="profileImage"
            width="112"
            height="112"
          ></v-img>
          <v-btn
            class="ma-n2 gradient"
            @click="(dialogLabel = $t('profile.profileImage')), (sheet = true)"
            icon
            absolute
            bottom
            :ripple="false"
            color="white"
            style="right: 5px"
          >
            <v-icon>
              {{
                $store.state.profile.data.profilePicture
                  ? "mdi-pencil"
                  : "mdi-camera"
              }}
            </v-icon>
          </v-btn>
        </v-avatar>
      </v-container>

      <v-bottom-sheet
        v-model="sheet"
        width="90%"
        overlay-color="backgroundoverlay"
        overlay-opacity="0.7"
      >
        <v-sheet style="background: transparent">
          <v-list style="border-radius: 10px">
            <v-list-item>
              <v-list-item-content
                @click="(sheet = false), (imageDialog = true)"
              >
                <v-list-item-title class="Text-Dark-Text-1---18-Center">
                  {{
                    $t("profile.changeProfilePicture", { image: dialogLabel })
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content @click="(sheet = false), removeImage()">
                <v-list-item-title class="Text-Dark-Text-1---18-Center">
                  {{
                    $t("profile.removeProfilePicture", { image: dialogLabel })
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list style="border-radius: 10px" class="mt-2 mb-5">
            <v-list-item>
              <v-list-item-content @click="sheet = false"
                ><v-list-item-title class="Text-Dark-Text-1---18-Center">
                  {{ $t("profile.cancel") }}</v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-bottom-sheet>

      <personal-info @deleteAccount="askForPassword = true" />

      <v-dialog scrollable :persistent="isPersistent" v-model="imageDialog">
        <v-card text>
          <v-card-title style="word-break: break-word">{{
            $t("profile.uploadImage", { image: dialogLabel })
          }}</v-card-title>
          <v-card-text>
            <v-form v-if="!showProgress" ref="validFileRef" v-model="validFile">
              <v-file-input
                style="overflow-x: hidden"
                :clearable="false"
                :rules="pictureRule"
                v-model="file"
                accept="image/png, image/jpeg, image/bmp, image/jpg"
                :placeholder="$t('profile.choosePicture')"
                prepend-icon="mdi-camera"
                show-size
                :hint="$t('profile.supportedTypes')"
                :persistent-hint="true"
              ></v-file-input>
            </v-form>
            <v-row v-if="showProgress">
              <v-col class="grow" align-self="center">
                <v-progress-linear :value="uploadValue"></v-progress-linear>
              </v-col>
              <v-col class="shrink">{{ uploadValue.toFixed() }}%</v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="!showProgress" class="mb-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="cancel()">{{
              $t("profile.back")
            }}</v-btn>
            <v-btn
              text
              color="primary"
              @click="validFile ? uploadImage() : $refs.validFileRef.validate()"
              >{{ $t("profile.ok") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-bottom-sheet v-model="askForPassword" content-class="profile-sheet"
        ><v-sheet
          class="pt-4 px-6"
          height="85vh"
          style="border-radius: 30px 30px 0px 0px"
        >
          <v-toolbar height="38px" flat class="mb-4">
            <v-spacer></v-spacer
            ><v-btn text @click="askForPassword = false" icon
              ><v-img
                width="24"
                height="24"
                contain
                :src="require('@/assets/reservation/close.svg')"
                :class="$store.state.settings.dark ? 'filter' : ''"
              ></v-img></v-btn
          ></v-toolbar>
          <div class="HeadingDarkH3-22Center mt-6">
            {{ $t("profile.deleteAccount") }}
          </div>
          <div class="Text-Dark-Text-1---18-Left-Align mt-6">
            {{ $t("profile.howToDeleteAccount") }}
          </div>
          <v-card
            elevation="3"
            style="border-radius: 13px !important"
            class="mt-8"
          >
            <v-text-field
              height="44"
              solo
              flat
              hide-details
              :placeholder="$t('profile.password') + '*'"
              v-model="pw"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-card>
          <v-row dense class="mx-3">
            <v-col cols="12">
              <v-spacer></v-spacer>
              <div class="Text-Color-Text-3---14-Right-Align mb-2">
                <router-link :to="{ name: 'resetPassword' }">
                  {{ $t("login.forgotOrSetPassword") }}
                </router-link>
              </div>
            </v-col>
          </v-row>
          <v-alert
            class="transition-swing"
            transition="scale-transition"
            type="error"
            v-model="error"
            >{{ errorMessage }}</v-alert
          >
          <v-card style="width: 100%" class="px-6" elevation="0">
            <v-card-actions>
              <v-btn
                width="100%"
                height="48"
                depressed
                color="primary"
                class="gradient Heading-White-H4-18-Center"
                @click="deleteAccount()"
                >{{ $t("profile.deleteAccount") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>

      <leaving-dialog
        @confirm="$store.dispatch('session/deleteAccount')"
        confirmText="confirmDelete"
        leavingText="delete"
      ></leaving-dialog>
    </v-container>
  </v-skeleton-loader>
</template>

<script>
import firebase from "firebase/app";
import "firebase/storage";
import PersonalInfo from "@/components/profile/PersonalInfo";
import BackToolbar from "@/components/app/common/BackToolbar";
import LeavingDialog from "@/components/profile/LeavingDialog";
export default {
  components: {
    PersonalInfo,
    BackToolbar,
    LeavingDialog,
  },
  data() {
    return {
      validFile: false,
      showProgress: false,
      isPersistent: false,
      editable: false,
      imageDialog: false,
      file: [],
      uploadValue: 0,
      dialogLabel: "Avatar",
      sheet: false,
      askForPassword: false,
      pw: "",
      showPassword: false,

      /* 'value.size > 0' query needed otherwise error would not disappear even after choosing a picture */
      pictureRule: [
        (value) =>
          !value || value.size > 0 || this.$t("profile.chooseUploadPicture"),
      ],
    };
  },
  methods: {
    cancel() {
      this.showProgress = false;
      this.isPersistent = false;
      this.imageDialog = false;
      this.$refs.validFileRef.reset();
    },
    update() {
      this.$store.dispatch("profile/updateProfile", {
        profile: this.profile,
      });
    },
    uploadImage() {
      this.showProgress = true;
      this.isPersistent = true;
      const storageRef = firebase
        .storage()
        .ref(`${this.profile.id}/${this.file.name}`)
        .put(this.file);
      storageRef.on(
        "state_changed",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            if (this.dialogLabel === this.$t("profile.profileImage")) {
              this.profileImage = url;
            } else {
              this.backgroundImage = url;
            }
            /* reset image upload dialog after finishing upload */
            this.showProgress = false;
            this.isPersistent = false;
            this.imageDialog = false;
            this.uploadValue = 0;
            this.file = [];
            this.$nextTick(() => {
              this.update();
            });
          });
        }
      );
    },
    removeImage() {
      if (this.dialogLabel === this.$t("profile.profileImage")) {
        this.profileImage = "";
      } else {
        this.backgroundImage = "";
      }
      this.update();
    },
    async deleteAccount() {
      const user = {
        email: this.profile.email,
        password: this.pw,
      };
      await this.$store.dispatch("session/signIn", {
        user,
        reload: false,
      });

      this.$store.commit(
        "profile/SET_DIALOG",
        this.$store.state.session.signinStatus === "success"
      );
    },
  },
  computed: {
    profile() {
      return this.$store.state.profile.data;
    },
    profileImage: {
      get() {
        if (this.profile.profilePicture) {
          return this.profile.profilePicture;
        } else {
          /* Default profile picture */
          return require("@/assets/avatar.png");
        }
      },
      set(val) {
        this.$store.commit("profile/SET_PROFILE_PICTURE", val);
      },
    },
    backgroundImage: {
      get() {
        if (this.profile.profileBackground) {
          return this.profile.profileBackground;
        } else {
          /* Default background */
          return require("@/assets/title_image.jpg");
        }
      },
      set(val) {
        this.$store.commit("profile/SET_PROFILE_BACKGROUND", val);
      },
    },
    error: {
      get() {
        return typeof this.$store.state.session.error === "string";
      },
    },
    errorMessage: {
      get() {
        return this.$store.state.session.error;
      },
    },
  },
};
</script>

<style>
.right-input input {
  text-align: right;
}
/* Overlay for the default background */
.profile-gradient:after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    112deg,
    #7432ff -27%,
    #7432ff 33%,
    #9766ff 64%,
    #dcccff 122%
  );
  opacity: 0.5;
}
</style>
