<template>
  <v-card
    style="
      border-radius: 30px 30px 0 0 !important;
      margin-top: -60px;
      z-index: 0;
    "
    class="px-2 pt-5"
    flat
  >
    <v-container
      ><v-img
        :src="require('@/assets/profile_bg.png')"
        style="position: absolute; top: 0; left: 0; z-index: -1"
      />
      <v-row>
        <v-col cols="12">
          <div class="HeadingDarkH2-28Center mt-8">
            <template v-if="profile.alias">
              {{ profile.alias }}
            </template>
            <template v-else>
              {{ profile.firstName }} {{ profile.lastName }}
            </template>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-card
            width="250"
            class="mx-auto my-3"
            style="border-radius: 20px !important"
            ><v-card-actions class="pa-1"
              ><v-btn
                style="border-radius: 20px"
                width="50%"
                depressed
                :class="
                  !travelType
                    ? 'gradient Heading-White-H6-14-Center white--text'
                    : 'Text-Dark-Text-3---14-Center transparent'
                "
                @click="travelType = !travelType"
                >{{ $t("profile.travel.private") }}</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                style="border-radius: 20px"
                width="50%"
                depressed
                :class="
                  travelType
                    ? 'gradient Heading-White-H6-14-Center white--text'
                    : 'Text-Dark-Text-3---14-Center transparent'
                "
                @click="travelType = !travelType"
                >{{ $t("profile.travel.business") }}</v-btn
              >
            </v-card-actions></v-card
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="HeadingDarkH4-18Left-Align mb-2">
            {{ $t("profile.infos") }}
          </div>
          <v-card style="border-radius: 10px !important">
            <v-text-field
              solo
              flat
              clearable
              :hide-details="firstNameErrors.length === 0"
              :placeholder="$t('profile.firstName')"
              v-model="firstName"
              :error-messages="firstNameErrors"
              maxlength="50"
              @input="$v.firstName.$touch()"
              @blur="getFirstNameErrors()"
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
              solo
              flat
              clearable
              :hide-details="lastNameErrors.length === 0"
              :placeholder="$t('profile.lastName')"
              v-model="lastName"
              :error-messages="lastNameErrors"
              maxlength="50"
              @input="$v.lastName.$touch()"
              @blur="getLastNameErrors()"
            ></v-text-field>
            <v-divider></v-divider>

            <v-text-field
              solo
              flat
              clearable
              :hide-details="aliasErrors.length === 0"
              :placeholder="$t('profile.alias')"
              v-model="alias"
              :error-messages="aliasErrors"
              maxlength="50"
              @input="$v.alias.$touch()"
              @blur="getAliasErrors()"
            ></v-text-field>
          </v-card>
        </v-col>
        <v-col cols="12" class="py-0">
          <div class="HeadingDarkH4-18Left-Align my-2">
            {{ $t("profile.gender") }}
          </div>
          <v-bottom-sheet
            v-model="genderModal"
            overlay-color="backgroundoverlay"
            overlay-opacity="1"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                height="60"
                style="border-radius: 10px !important"
                solo
                :placeholder="$t('profile.gender')"
                v-model="gender"
                readonly
                hide-details
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-sheet
              class="text-center"
              height="288"
              style="border-radius: 30px 30px 0px 0px"
            >
              <v-toolbar
                height="38px"
                flat
                style="border-radius: 10px; background: transparent"
                class="mb-4"
              >
                <v-spacer></v-spacer
                ><v-btn text @click="genderModal = false">{{
                  $t("introduction.done")
                }}</v-btn></v-toolbar
              >
              <v-radio-group v-model="gender" class="mt-0">
                <v-list
                  class="mx-6"
                  style="border-radius: 10px !important"
                  :style="
                    !$store.state.settings.dark
                      ? 'background-color: #f5f5f5;'
                      : ''
                  "
                >
                  <div v-for="(gender, index) in genders" :key="index">
                    <v-list-item style="height: 60px">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-radio :value="gender.text" :label="gender.text">
                          </v-radio>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="index < genders.length - 1"></v-divider>
                  </div>
                </v-list>
              </v-radio-group>
            </v-sheet>
          </v-bottom-sheet>
        </v-col>
        <v-col cols="12" class="pb-0">
          <div class="HeadingDarkH4-18Left-Align my-2">
            {{ $t("profile.relationshipStatus") }}
          </div>
          <v-bottom-sheet
            v-model="relationshipModal"
            overlay-color="backgroundoverlay"
            overlay-opacity="1"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                height="60"
                style="border-radius: 10px !important"
                solo
                :placeholder="$t('profile.relationshipStatus')"
                v-model="relationshipStatus"
                readonly
                hide-details
                v-on="on"
              ></v-text-field>
            </template>
            <v-sheet
              class="text-center"
              height="322"
              style="border-radius: 30px 30px 0px 0px"
            >
              <v-toolbar
                height="38px"
                flat
                style="border-radius: 10px; background: transparent"
                class="mb-4"
              >
                <v-spacer></v-spacer
                ><v-btn text @click="relationshipModal = false">{{
                  $t("introduction.done")
                }}</v-btn></v-toolbar
              >
              <v-radio-group v-model="relationshipStatus" class="mt-0">
                <v-list
                  class="mx-6"
                  style="border-radius: 10px !important"
                  :style="
                    !$store.state.settings.dark
                      ? 'background-color: #f5f5f5;'
                      : ''
                  "
                >
                  <div
                    v-for="(state, index) in relationshipStates"
                    :key="index"
                  >
                    <v-list-item style="height: 60px">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-radio :value="state.text" :label="state.text">
                          </v-radio>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-if="index < relationshipStates.length - 1"
                    ></v-divider>
                  </div>
                </v-list>
              </v-radio-group>
            </v-sheet>
          </v-bottom-sheet>
        </v-col>
        <v-col cols="12">
          <div class="HeadingDarkH4-18Left-Align my-2">
            {{ $t("profile.birthday") }}
          </div>
          <date-picker
            :value="birthday"
            @date-changed="
              (date) => {
                birthday = date;
              }
            "
          ></date-picker>
        </v-col>
        <v-col
          cols="12"
          v-if="
            $store.state.session.user.providerData[0].providerId === 'password'
          "
        >
          <div class="HeadingDarkH4-18Left-Align my-2">
            {{ $t("profile.changePassword") }}
          </div>
          <v-card style="border-radius: 10px !important">
            <v-text-field
              height="60"
              solo
              flat
              clearable
              :hide-details="!wrongPassword"
              :placeholder="$t('profile.currentPassword')"
              :error-messages="
                wrongPassword ? [$t('profile.wrongPassword')] : []
              "
              v-model="password"
              maxlength="50"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPassword = !showPassword"
              @input="$v.newPassword.$touch(), $v.newPassword2.$touch()"
              @blur="getNewPasswordErrors(), getNewPassword2Errors()"
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
              height="60"
              solo
              flat
              clearable
              :hide-details="newPasswordErrors.length === 0"
              :placeholder="$t('profile.newPassword')"
              v-model="newPassword"
              :error-messages="newPasswordErrors"
              maxlength="50"
              :type="showNewPassword ? 'text' : 'password'"
              :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showNewPassword = !showNewPassword"
              @blur="getNewPasswordErrors(), getNewPassword2Errors()"
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
              height="60"
              solo
              flat
              clearable
              :hide-details="newPassword2Errors.length === 0"
              :placeholder="$t('profile.confirmNewPassword')"
              v-model="newPassword2"
              :error-messages="newPassword2Errors"
              maxlength="50"
              :type="showNewPassword2 ? 'text' : 'password'"
              :append-icon="showNewPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showNewPassword2 = !showNewPassword2"
              @blur="getNewPasswordErrors(), getNewPassword2Errors()"
            ></v-text-field>
          </v-card>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-btn
            absolute
            text
            right
            class="pb-4 grey--text"
            @click="$emit('deleteAccount')"
            >{{ $t("profile.deleteAccount") }}</v-btn
          >
        </v-col>
        <v-col cols="12" class="pb-0">
          <div class="HeadingDarkH4-18Left-Align my-2">
            {{ $t("profile.contactInformation") }}
          </div>
          <v-card style="border-radius: 10px !important">
            <v-text-field
              height="60"
              solo
              flat
              hide-details
              readonly
              :placeholder="$t('profile.email')"
              maxlength="100"
              v-model="email"
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
              solo
              flat
              clearable
              :hide-details="phoneErrors.length === 0"
              :placeholder="$t('profile.phone')"
              persistent-hint
              :hint="$t('profile.phoneNumberHint')"
              v-model="phone"
              :error-messages="phoneErrors"
              maxlength="50"
              @change="phone ? (phone = phone.trim()) : ''"
              @blur="getPhoneErrors()"
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
              solo
              flat
              clearable
              :hide-details="addressErrors.length === 0"
              :placeholder="$t('profile.address')"
              v-model="address"
              :error-messages="addressErrors"
              maxlength="100"
              @input="$v.address.$touch()"
              @blur="getAddressErrors()"
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
              solo
              flat
              clearable
              :hide-details="cityErrors.length === 0"
              :placeholder="$t('profile.city')"
              v-model="city"
              :error-messages="cityErrors"
              maxlength="50"
              @input="$v.city.$touch()"
              @blur="getCityErrors()"
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
              solo
              flat
              clearable
              :hide-details="postalErrors.length === 0"
              :placeholder="$t('profile.postal')"
              v-model="postal"
              :error-messages="postalErrors"
              maxlength="50"
              @input="$v.postal.$touch()"
              @blur="getPostalErrors()"
            ></v-text-field>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div
            v-html="$t('profile.reviewTerms')"
            class="v-html Text-Dark-Text-3---14-Left-Align"
          ></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            height="48"
            min-width="150"
            depressed
            class="gradient d-block mx-auto Heading-White-H4-18-Center"
            dark
            @click="update()"
            color="primary"
            >{{ $t("profile.save") }}</v-btn
          >
        </v-col>
      </v-row>

      <error-dialog
        v-if="dialog"
        :errorMessage="dialogMessage"
        @acknowledge="acknowledgeDialog()"
        @close="acknowledgeDialog()"
      />
    </v-container>
  </v-card>
</template>

<script>
import DatePicker from "@/components/app/DatePicker";
import ErrorDialog from "@/components/app/common/ErrorDialog";
import {
  required,
  sameAs,
  minLength,
  numeric,
  helpers,
  requiredIf,
} from "vuelidate/lib/validators";

export default {
  components: {
    DatePicker,
    ErrorDialog,
  },
  validations: {
    firstName: {
      required,
      isValid: helpers.regex("isValid", /^[A-Za-zÀ-ÖØ-öø-ÿ0-9_ ]*$/),
    },
    lastName: {
      required,
      isValid: helpers.regex("isValid", /^[A-Za-zÀ-ÖØ-öø-ÿ0-9_ ]*$/),
    },
    alias: {
      isValid: helpers.regex("isValid", /^[A-Za-zÀ-ÖØ-öø-ÿ0-9_ ]*$/),
    },
    phone: {
      isValid: helpers.regex("isValid", /^\+[0-9]*$/),
      minLength: minLength(8),
    },
    newPassword: {
      minLength: minLength(8),
      required: requiredIf(function () {
        return !!this.password;
      }),
    },
    newPassword2: { sameAsNewPassword: sameAs("newPassword") },
    address: {
      isValid: helpers.regex("isValid", /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\/\,\-\._ ]*$/),
    },
    city: {
      isValid: helpers.regex("isValid", /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\/\,\-\._ ]*$/),
    },
    postal: { numeric },
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      alias: "",
      phone: "",
      password: "",
      wrongPassword: this.$store.state.session.signinStatus === "failure",
      showPassword: false,
      newPassword: "",
      showNewPassword: false,
      newPassword2: "",
      showNewPassword2: false,
      savedChanges: false,
      address: "",
      city: "",
      postal: "",
      relationshipStatus: "",
      gender: "",
      email: "",
      birthday: "",
      relationshipStates: [
        { id: 0, text: this.$t("profile.relationship.single") },
        { id: 1, text: this.$t("profile.relationship.married") },
        { id: 2, text: this.$t("profile.relationship.relationship") },
        { id: 3, text: this.$t("profile.genders.notSay") },
      ],
      dateModal: false,
      newDate: void 0,
      genderGroup: 0,
      genders: [
        { id: 0, text: this.$t("profile.genders.male") },
        { id: 1, text: this.$t("profile.genders.female") },
        { id: 2, text: this.$t("profile.genders.notSay") },
      ],
      travelType: false,
      genderModal: false,
      relationshipModal: false,
      firstNameErrors: [],
      lastNameErrors: [],
      aliasErrors: [],
      phoneErrors: [],
      newPasswordErrors: [],
      newPassword2Errors: [],
      addressErrors: [],
      cityErrors: [],
      postalErrors: [],
    };
  },
  props: ["editable"],
  computed: {
    profile() {
      return this.$store.state.profile.data;
    },
    dialog() {
      return this.savedChanges;
    },
    dialogMessage() {
      return this.savedChanges ? this.$t("profile.savedChanges") : "";
    },
  },
  methods: {
    update() {
      try {
        this.$v.$touch();

        this.getFirstNameErrors();
        this.getLastNameErrors();
        this.getAliasErrors();
        this.getNewPasswordErrors();
        this.getNewPassword2Errors();
        this.getPhoneErrors();
        this.getAddressErrors();
        this.getCityErrors();
        this.getPostalErrors();

        if (this.$v.invalid) {
          return;
        }

        if (this.password !== "") {
          this.changePassword();
        }
        this.profile.firstName = this.firstName;
        this.profile.lastName = this.lastName;
        this.profile.alias = this.alias;
        this.profile.phone = this.phone;
        this.profile.address = this.address;
        this.profile.city = this.city;
        this.profile.postal = this.postal;
        this.profile.relationshipStatus = this.relationshipStatus;
        this.profile.gender = this.gender;
        this.profile.birthday = this.birthday;
        this.profile.travelType = this.travelType;
        this.$store.dispatch("profile/updateProfile", {
          profile: this.profile,
        });
        this.savedChanges = true;
      } catch (e) {
        console.log(e);
      }
    },
    acknowledgeDialog() {
      this.savedChanges = false;
    },
    async changePassword() {
      const user = {
        email: this.profile.email,
        password: this.password,
      };
      await this.$store.dispatch("session/signIn", {
        user,
        reload: false,
      });
      if (this.$store.state.session.signinStatus === "fetching") {
        this.$store.commit("session/SUCCESS_REQUEST_SIGN_IN");
        await this.$store.dispatch("session/changePassword", {
          newPassword: this.newPassword,
        });
      }
      return;
    },
    /* Fix for elderly accounts, where the gender and relationship state would display numbers instead of texts */
    correctGender() {
      if (this.profile.gender === 0) {
        this.profile.gender = this.$t("profile.genders.male");
        this.update();
      } else if (this.profile.gender === 1) {
        this.profile.gender = this.$t("profile.genders.female");
        this.update();
      } else if (this.profile.gender === 2) {
        this.profile.gender = this.$t("profile.genders.notSay");
        this.update();
      }
    },
    correctRelationshipState() {
      if (this.profile.relationshipStatus === 0) {
        this.profile.relationshipStatus = this.$t(
          "profile.relationship.single"
        );
        this.update();
      } else if (this.profile.relationshipStatus === 1) {
        this.profile.relationshipStatus = this.$t(
          "profile.relationship.married"
        );
        this.update();
      } else if (this.profile.relationshipStatus === 2) {
        this.profile.relationshipStatus = this.$t(
          "profile.relationship.relationship"
        );
        this.update();
      }
    },
    getFirstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) {
        this.firstNameErrors = errors;
        return false;
      }

      !this.$v.firstName.required &&
        errors.push(this.$t("profile.requireFirstname"));

      !this.$v.firstName.isValid &&
        errors.push(
          this.$t("profile.alphanumeric", {
            fieldName: "First name",
          })
        );

      this.firstNameErrors = errors;
      return errors.length > 0;
    },
    getLastNameErrors() {
      const errors = [];

      if (!this.$v.lastName.$dirty) {
        this.lastNameErrors = [];
        return false;
      }

      !this.$v.lastName.required &&
        errors.push(this.$t("profile.requireLastname"));

      !this.$v.lastName.isValid &&
        errors.push(
          this.$t("profile.alphanumeric", {
            fieldName: "Last name",
          })
        );

      this.lastNameErrors = errors;
      return errors.length > 0;
    },
    getAliasErrors() {
      const errors = [];
      !this.$v.alias.isValid &&
        errors.push(
          this.$t("profile.alphanumeric", {
            fieldName: "Alias",
          })
        );

      this.aliasErrors = errors;
      return errors.length > 0;
    },
    getPhoneErrors() {
      const errors = [];

      if (!this.$v.phone.$dirty) {
        this.phoneErrors = errors;
        return false;
      }

      !this.$v.phone.isValid && errors.push(this.$t("profile.validPhone"));

      !this.$v.phone.minLength &&
        errors.push(
          this.$t("profile.minLength", {
            fieldName: "Phone",
            minLength: "8",
          })
        );

      this.phoneErrors = errors;
      return errors.length > 0;
    },
    getNewPasswordErrors() {
      const errors = [];

      if (!this.$v.newPassword.$dirty) {
        this.newPasswordErrors = errors;
        return false;
      }

      !this.$v.newPassword.required &&
        errors.push(this.$t("profile.enterNewPassword"));

      !this.$v.newPassword.minLength &&
        errors.push(this.$t("profile.atLeastChars"));

      this.newPasswordErrors = errors;
      return errors.length > 0;
    },
    getNewPassword2Errors() {
      const errors = [];

      if (!this.$v.newPassword2.$dirty) {
        this.newPassword2Errors = errors;
        return false;
      }

      !this.$v.newPassword2.sameAsNewPassword &&
        errors.push(this.$t("profile.notIdentical"));

      this.newPassword2Errors = errors;
      return errors.length > 0;
    },
    getAddressErrors() {
      const errors = [];

      if (!this.$v.address.$dirty) {
        this.addressErrors = errors;
        return false;
      }

      !this.$v.address.isValid &&
        errors.push(
          this.$t("profile.alphanumericAndSpecialChars", {
            fieldName: "Address",
          })
        );

      this.addressErrors = errors;
      return errors.length > 0;
    },
    getCityErrors() {
      const errors = [];

      if (!this.$v.city.$dirty) {
        this.cityErrors = errors;
        return false;
      }

      !this.$v.city.isValid &&
        errors.push(
          this.$t("profile.alphanumericAndSpecialChars", {
            fieldName: "City name",
          })
        );

      this.cityErrors = errors;
      return errors.length > 0;
    },
    getPostalErrors() {
      const errors = [];

      if (!this.$v.postal.$dirty) {
        this.postalErrors = errors;
        return false;
      }

      !this.$v.postal.numeric &&
        errors.push(
          this.$t("profile.numeric", {
            fieldName: "Postal code",
          })
        );

      this.postalErrors = errors;
      return errors.length > 0;
    },
  },
  beforeMount() {
    this.correctGender();
    this.correctRelationshipState();
    this.firstName = this.profile.firstName;
    this.lastName = this.profile.lastName;
    this.alias = this.profile.alias;
    this.phone = this.profile.phone;
    this.address = this.profile.address;

    this.city = this.profile.city;

    /** BUGFIX #255
     * City appearing as [object object]
     * this.profile.city is eather an object with "en" and "de"
     * attributes, or a string representing a city.
     */
    if (this.city.de || this.city.en) {
      this.city =
        this.$i18n.locale() === "en-US"
          ? this.profile.city.en
          : this.profile.city.de;
    }

    this.postal = this.profile.postal;
    this.relationshipStatus = this.profile.relationshipStatus;
    this.gender = this.profile.gender;
    this.email = this.profile.email;
    this.birthday = this.profile.birthday;
    this.travelType = this.profile.travelType;
  },
};
</script>
<style lang="scss">
.smooth-picker {
  background-color: transparent !important;
  .smooth-handle-layer .smooth-top,
  .smooth-bottom {
    background: transparent !important;
  }
}
.v-menu__content.menuable__content__active {
  border-radius: 10px !important;
}
.v-text-field i.v-icon {
  color: inherit;
}
</style>
